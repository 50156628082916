const cardRoutes = {
    path: '/card',
    name: 'Card Management',
    roles: ['Admin'],
    icon: 'dripicons-card',
    children: [
        {
            path: '/card/view',
            name: 'EDGE Cards',
        },

        {
            path: '/card/transactions',
            name: 'Card Transactions',
        },
    ],
};
const depositRoutes = {
    path: '/deposit',
    name: 'Deposit Funds',
    roles: ['Admin'],
    icon: 'dripicons-card',
    children: [
        {
            path: '/deposit/bank_deposit',
            name: 'Bank Deposit',
        },

        {
            path: '/deposit/card_deposit',
            name: 'Card Deposit',
        },
    ],
};

const responsibleBettingAppRoutes = {
    path: '/betting',
    name: 'Responsible Betting',
    roles: ['Admin'],
    icon: 'dripicons-briefcase',
    children: [
        {
            path: '/betting/notification',
            name: 'Email Notifications',
        },
        {
            path: '/betting/manage-limits',
            name: 'Manage Limits',
        },
        {
            path: '/betting/cooldown',
            name: 'Cooldown',
        },
    ],
};

const tutorialAppRoutes = {
    path: '/tutorials',
    name: 'Tutorials',
    roles: [],
    icon: 'dripicons-article',
    children: [
        {
            path: '/tutorials/setup',
            name: 'Setup Guide',
        },
    ],
};

const accountAppRoutes = {
    path: '/account',
    name: 'Account',
    roles: [],
    icon: 'dripicons-user',
    children: [
        {
            path: '/account/profile',
            name: 'Profile',
        },
        {
            path: '/account/statement',
            name: 'Monthly Statement',
        },
        {
            path: '/account/deposit-history',
            name: 'Deposit History',
        },
    ],
};

const legalAppRoutes = {
    path: '/legal',
    name: 'Legal',
    roles: [],
    icon: 'dripicons-information',
    children: [
        {
            path: '/legal/documents',
            name: 'Documents',
        },
    ],
};
const appRoutes = [
    depositRoutes,

    {
        path: '/funds/withdraw',
        name: 'Withdraw Funds',
        icon: 'dripicons-upload',
    },
    {
        path: '/bank/accounts',
        name: 'Manage Accounts',
        icon: 'dripicons-wallet',
    },
    cardRoutes,
    responsibleBettingAppRoutes,
    tutorialAppRoutes,
    accountAppRoutes,
    legalAppRoutes,
];

const authProtectedRoutes = [...appRoutes];

export default authProtectedRoutes;
